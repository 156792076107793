import React, { useState } from 'react';
import CustomeTextInput from '../components/CustomeTextInput';
const Footer = () => {
  const [email, setEmail] = useState(""); // State for the newsletter input
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (email) {
      try {
        const response = await fetch('https://uwcwwlvt42.execute-api.us-east-1.amazonaws.com/dev/subscribe', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        })
        
        if (response.ok) {
          setIsSubmitted(true);
          setEmail("");
          setTimeout(() => setIsSubmitted(false), 3000);
        } else {
          throw new Error('Failed to submit your email')
        }
      } catch (error) {
        console.error('Error submitting email:', error);
      }
    }
  };

  return (
    <footer className="bg-black text-white py-6 sm:py-8 md:py-10">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex flex-col sm:flex-row flex-wrap justify-around gap-6 sm:gap-8">
        {/* Logo Section */}
        <div className="w-full sm:w-1/2 md:w-1/3 mb-6 sm:mb-0">
          <div className="items-center">
            <h2 className="text-xl sm:text-2xl font-bold">Evovance</h2>
            <p className="mt-2 text-sm sm:text-base font-dmSans">Evolve every day</p>
          </div>
          <div className="flex mt-4 space-x-3">
            <a href="https://www.instagram.com/evovancehq" 
               className="bg-white rounded-full p-2 hover:bg-gray-200 transition-colors"
               target="_blank"
               rel="noopener noreferrer">
              <img
                src="https://cdn-icons-png.flaticon.com/512/174/174855.png"
                alt="Instagram"
                className="w-4 h-4 sm:w-5 sm:h-5"
              />
            </a>
            <a href="https://www.linkedin.com/company/evovance" 
               className="bg-white rounded-full p-2 hover:bg-gray-200 transition-colors"
               target="_blank"
               rel="noopener noreferrer">
              <img
                src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                alt="LinkedIn"
                className="w-4 h-4 sm:w-5 sm:h-5"
              />
            </a>
          </div>
        </div>

        {/* Contact Section */}
        <div className="w-full sm:w-1/2 md:w-1/4 mb-6 sm:mb-0">
          <h3 className="text-base sm:text-lg font-semibold mb-3 sm:mb-4">Contact Us</h3>
          <p className="text-sm sm:text-base mb-4 sm:mb-6 font-dmSans font-light">408-409-6323</p>
          <p className="text-sm sm:text-base font-dmSans text-[#F4F4F4] font-light">
            akshay@evovance.com
          </p>
        </div>

        {/* Newsletter Section */}
        <div className="w-full sm:w-1/2 md:w-1/3 max-w-md mx-auto sm:mx-0 mt-4 sm:mt-0">
        <form 
              onSubmit={handleSubmit} 
              className="mt-2 flex flex-col items-start space-y-2 w-full"
            >
          <h3 className="text-base sm:text-lg font-semibold mb-3 sm:mb-4">Get updates</h3>
          <CustomeTextInput email={email} setEmail={setEmail} />
          {isSubmitted && (
                <span className="text-green-500 text-xs sm:text-sm">
                  Thank you for submitting your email! We will be in touch soon.
                </span>
              )}
          </form>
        </div>
      </div>
    </footer>
  );
};

export default Footer;