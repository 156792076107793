import React from 'react';
import akshay from '../assest/akshay.png';
import sachin from '../assest/sachin.png';
import shashank from '../assest/shashank.png';
const AboutUs = () => {
  const teamMembers = [
    {
      name: "Akshay Jain",
      title: "Co-founder",
      image: akshay,
      bio: "I'm a data-driven problem solver on a mission to make discoveries that improve lives. With over a decade of software engineering experience, I've held impactful roles at Helix, Robinhood, Uber, and Facebook, driving innovation and efficiency. I hold a Master's in Computer Science from NYU and enjoy reading and hiking in my free time."
    },
    {
      name: "Sachin Garg",
      title: "Co-founder",
      image: sachin,
      bio: "I am a physician, strategist, and digital health leader passionate about creating solutions that bridge science, empathy, and impact. With expertise in clinical research and healthcare technology, I've led initiatives like rapid diagnostic launches and global expert networks. My diverse background in medicine, consulting, and innovation equips me to drive meaningful change and foster growth."
    },
    {
      name: "Shashank Jain",
      title: "Co-founder",
      image: shashank,
      bio: "I am designer dedicated to creating technology that fosters human connection and inspires growth. I've led impactful projects across healthcare, education and AI, simplifying complexity to empower users. Passionate about building communities, I strive to bring people together through meaningful experiences."
    }
  ];

  return (
    <div className="max-w-6xl mx-auto px-4 py-16">
      <h1 className="text-4xl md:text-5xl font-bold text-center mb-16">About us</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {teamMembers.map((member, index) => (
          <div key={index} className="bg-gray-100 rounded-lg pt-8 pb-8 pl-4 pr-4 text-center">
            <div className="flex justify-center mb-4">
              {index === 2 ? (
                <div className="w-32 h-32 rounded-full bg-gray-900 flex items-center justify-center overflow-hidden">
                  <img 
                    src={member.image} 
                    alt={member.name} 
                    className="w-full h-full object-cover"
                  />
                </div>
              ) : (
                <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden">
                  <img 
                    src={member.image} 
                    alt={member.name} 
                    className="w-full h-full object-cover"
                  />
                </div>
              )}
            </div>
            
            <h2 className="text-2xl font-bold mb-1">{member.name}</h2>
            <p className="text-gray-600 mb-6">{member.title}</p>
            
            <p className="font-inter font-light text-[16px] leading-[28px] tracking-[0px] text-center flex items-center justify-center">
              {member.bio}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutUs;